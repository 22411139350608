<template>
    <div class="mx-5 my-5">
        <v-row>
            <v-col md="12" class="d-flex" style="justify-content: space-between; align-items: center">
                <v-row>
                    <h2 v-if="type == 'ORDER'">PO-{{ invoiceTypeLabel }}</h2>
                    <h2 v-if="type == 'REQUEST'">PR-{{ invoiceTypeLabel }}</h2>
                </v-row>
            </v-col>
            <v-col md="3" class="py-0">
                <v-autocomplete :label="$t('Client Name')" outlined dense readonly color="#757575" item-text="name" item-value="id"
                    v-model="invoice.client_account_id" :items="clientAccounts" :loading="clientAccountLoading"
                    v-debounce="clientAc"></v-autocomplete>
            </v-col>
            <v-col md="3" class="py-0">
                <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field :label="$t('date')" outlined dense persistent-hint prepend-icon="mdi-calendar"
                            v-bind="attrs" v-on="on" v-model="invoiceDateRequest"></v-text-field>
                    </template>
                    <v-date-picker v-model="invoiceDateRequest" no-title @input="dateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col md="3" class="py-0">
                <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="invoiceTimeRequest" transition="scale-transition" offset-y max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="invoiceTimeRequest" :label="$t('Time')"
                            prepend-icon="mdi-clock-time-four-outline" readonly outlined dense v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="timeMenu" v-model="invoiceTimeRequest" full-width
                        @click:minute="$refs.timeMenuRef.save(invoiceTimeRequest)"></v-time-picker>
                </v-menu>
            </v-col>
            <v-col md="3" class="py-0">
                <v-text-field :label="$t('Receiving/Delivery Number')" outlined dense
                    v-model="invoice.request_serial"></v-text-field>
            </v-col>
            <v-col v-if="type == 'ORDER'" md="3" class="py-0">
                <v-menu ref="menu1" v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                    offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field :label="$t('Order Date')" outlined dense persistent-hint prepend-icon="mdi-calendar"
                            v-bind="attrs" v-on="on" v-model="invoiceDateOrder"></v-text-field>
                    </template>
                    <v-date-picker v-model="invoiceDateOrder" no-title @input="dateMenu = false"></v-date-picker>
                </v-menu>
            </v-col>
            <v-col v-if="type == 'ORDER'" md="3" class="py-0">
                <v-menu ref="timeMenuRef" v-model="timeMenu" :close-on-content-click="false" :nudge-right="40"
                    :return-value.sync="invoiceTimeOrder" transition="scale-transition" offset-y max-width="290px"
                    min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="invoiceTimeOrder" :label="$t('Order Time')"
                            prepend-icon="mdi-clock-time-four-outline" readonly outlined dense v-bind="attrs"
                            v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="timeMenu" v-model="invoiceTimeOrder" full-width
                        @click:minute="$refs.timeMenuRef.save(invoiceTimeRequest)"></v-time-picker>
                </v-menu>
            </v-col>
            <v-col v-if="type == 'ORDER'" md="3" class="py-0">
                <v-text-field style="font-weight: bold; font-size: larger;" :label="$t('Order Number')" outlined dense
                    v-model="invoice.order_serial"></v-text-field>
            </v-col>
            <v-col md="3" class="py-0">
                <v-autocomplete :label="$t('Inventory')" outlined dense color="#757575" item-text="name" item-value="id" no-filter
                    v-model="invoice.inventory_id" :loading="inventoriesLoading" :items="inventories" return-object
                    v-debounce="inventoriesAc" @input="matchMaterialInventoriesHandler"></v-autocomplete>
            </v-col>
            <v-col md="6" class="py-0">
                <v-text-field :label="$t('notes')" v-model="invoice.notes" outlined dense></v-text-field>
            </v-col>
            <v-card md="10" class="my-5 mx-5">
                <v-container  id="buffet-table" fluid tag="section">
                    <v-simple-table :dark="$store.state.isDarkMode"  style="width: max-content;">
                        <tr>
                            <th v-if="tableView[0].value" style="padding-left: 100px;  padding-right: 100px;">{{ $t('Material') }}</th>
                            <th v-if="tableView[1].value" id="idAutoComp">{{ $t('Type') }}</th>
                            <th v-if="tableView[2].value" id="idPrice">{{ $t('quantity') }}</th>
                            <th id="idPrice">{{ $t('Delivered Materials') }}</th>
                            <th id="idPrice">{{ $t('Receiving/Delivery') }}</th>
                            <th v-if="tableView[3].value">{{ $t('Unit') }}</th>
                            <th v-if="tableView[13].value" id="idAutoComp">{{ $t('explain') }}</th>
                        </tr>
                        <v-fade-transition mode="out-in" :group="true" tag="tbody">
                        <tr v-for="(material, ix) in invoice.materials" :key="ix">

                            <td v-if="tableView[0].value">
                                <AutoCompleteField label="" v-model="material.material_id"
                                    endPoint="/inventories/materials/auto-complete" returnObject
                                    @input="onMaterialInput(material)" />
                            </td>
                            <td v-if="tableView[1].value">
                                <v-autocomplete dense full-width hide-details  flat :items="material.type"
                                    v-model="material.type_id" item-text="name" item-value="id">
                                </v-autocomplete>
                            </td>
                            <td v-if="tableView[2].value">
                                <v-text-field full-width dense hide-details readonly  flat type="number" color="#757575"
                                   v-model.number="material.allquantity"></v-text-field>
                            </td>
                            <td>
                                <v-text-field full-width dense hide-details readonly  flat color="#757575"
                                   v-model.number="material.deliveried"></v-text-field>
                            </td>
                            <td>
                                <v-text-field full-width dense hide-details  type="number" flat color="#757575"
                                    v-model.number="material.quantity"></v-text-field>
                            </td>
                            <td v-if="tableView[3].value">{{ $t('Piece') }}</td>
                            <td v-if="tableView[13].value">
                                <v-text-field full-width dense hide-details  flat color="#757575"
                                    v-model="material.notes"></v-text-field>
                            </td>
                        </tr>
                    </v-fade-transition>
                    </v-simple-table>
                </v-container>
            </v-card>
            <v-col md="4">
                <v-btn color="blue" :disabled="!isNew && is_locked" block @click="createNewInvoice" :loading="submitting">{{
                 $t('Receiving/Delivery') }}</v-btn>

            </v-col>
            <v-col v-if="!isNew" md="1">
                <v-btn color="green" @click="showAttachment">
                    <v-icon class="white--text" size="20">mdi-paperclip</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog width="700" v-model="dialogAttachment">
            <v-card class="">
                <v-card-title class="justify-center">
                    <h2>{{ }}</h2>
                </v-card-title>
                <v-card-text class="d-flex flex-column justify-center align-center">
                    <div v-for="attachment in invoice.attachments" :key="attachment.id" class="add-user__chip1">
                        <v-row style="font-weight: bold;">
                            <v-col md="1"></v-col>
                            <v-col md="4">{{ attachment.user.name }}</v-col>
                            <v-col md="7">{{ attachment.date }}</v-col>
                        </v-row>
                        <v-row>
                            <v-col md="4">
                                <div class="cardd">
                                    <div>
                                        <v-row>
                                            <v-col md="9">
                                                <img class="preview-img" :src="attachment.path" />
                                            </v-col>
                                            <v-col md="3">
                                                <v-icon v-if="returnAbility('invoice:delete:attachment')" color="red"
                                                    @click="() => {
                                                            deleteAttachmentDialog = true;
                                                            attachmentId = attachment.id;
                                                        }
                                                        ">mdi-delete</v-icon>
                                                <div class="mt-5">
                                                    <FileComponent :Id="attachment.id"></FileComponent>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                    <div>
                                        <v-row>
                                            <v-col md="9">
                                                <div class="test" :title="attachment.name">
                                                    <span>
                                                        {{ attachment.name }} -

                                                        {{ Math.round(attachment.size / 1000) + "kb" }}</span>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <app-attachment-modal path="invoiceOrder" @fetchData="showAttachment"></app-attachment-modal>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteAttachmentDialog" width="500">
            <v-card>
                <v-card-title class="d-flex justify-center">
                    <h2>? {{ $t("are you sure delete ") }}</h2>
                </v-card-title>
                <v-card-actions class="justify-center">
                    <v-btn color="green" class="white--text" depressed @click="deleteAttachment(attachmentId)"
                        :loading="deleteBtnLoading">{{ $t("submit") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-row><v-col></v-col></v-row>
        <v-row><v-col></v-col></v-row>
        <v-row><v-col></v-col></v-row>
        <v-row><v-col></v-col></v-row>
        <Footer v-if="!isNew" path="invoiceOrder" class="d-flex justify-end" />
    </div>
</template>
  
<script>
import axios from "axios";
import AutoCompleteField from "../invoices/AutoCompleteField.vue";
import { saveAs } from 'file-saver';
import Footer from '../invoices/Footer.vue';
import attachmentModal from "../invoices/attachmentModal.vue";
import FileComponent from "../teachers/download.vue";
import i18n from "@/i18n";

// import Installment from "@/views/dashboard/modules/invoices/Installment.vue";
export default {
    components: { AutoCompleteField, Footer, FileComponent, appAttachmentModal: attachmentModal, },
    props: {
        isNew: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            wearhouse: this.$route.query.wear_house,
            dialog: false,
            dialogAttachment: false,
            deliveried: null,
            invoice_status: null,
            delivery_note_count: null,
            approve: false,
            tableView: [
                {
                    name: "المادة",
                    value: true,
                },
                {
                    name: "النوع",
                    value: true,
                },
                {
                    name: "الكمية",
                    value: true,
                },
                {
                    name: "الوحدة",
                    value: true,
                },
                {
                    name: "السعر",
                    value: true,
                },
                {
                    name: "المجموع",
                    value: true,
                },
                {
                    name: "الحسم",
                    value: true,
                },
                {
                    name: 'إضافة',
                    value: true,
                },
                {
                    name: "الصافي",
                    value: true,
                },
                {
                    name: "المستودع",
                    value: true,
                },
                {
                    name: "الحساب المقابل",
                    value: true,
                },
                {
                    name: "المستفيد",
                    value: true,
                },
                {
                    name: "نسبة المستفيد",
                    value: true,
                },
                {
                    name: "الشرح",
                    value: true,
                },


            ],
            installments: [],
            installmentdialog: false,
            is_locked: false,
            timeMenu: false,
            timeMenuRef: null,
            invoiceDateRequest: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            invoiceTimeRequest: `${String(new Date().getHours()).length == 1
                    ? `0${new Date().getHours()}`
                    : new Date().getHours()
                }:${String(new Date().getMinutes()).length == 1
                    ? `0${new Date().getMinutes()}`
                    : new Date().getMinutes()
                }`,
            invoiceDateOrder: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10),
            invoiceTimeOrder: `${String(new Date().getHours()).length == 1
                    ? `0${new Date().getHours()}`
                    : new Date().getHours()
                }:${String(new Date().getMinutes()).length == 1
                    ? `0${new Date().getMinutes()}`
                    : new Date().getMinutes()
                }`,
            invoiceTypeLabel: null,
            fetchingMetadata: false,
            invoiceMetadata: null,
            disscountType: "PERCENTAGE",
            paymentMethods: [
                {
                    label: i18n.t('Cash'),
                    value: "CASH",
                },
                {
                    label: i18n.t('Credit'),
                    value: "CREDIT",
                },
                {
                    label: i18n.t('Installment'),
                    value: "INSTALLMENT",
                },
            ],
            dateMenu: false,
            attachmentId: null,
            invoice_type: null,
            invoice: {
                client_account_id: null,
                date: null,
                request_serial: null,
                order_serial: null,
                notes: null,
                discount_value: null,
                discount_type: null,
                invoice_type_id: this.$route.query.invoice_id,
                cost_center_id: null,
                inventory_id: null,
                payment_method: null,
                cash_account_id: null,
                discount_account_id: null,
                paid_amount: null,
                is_locked: false,
                invoice_expenses: [],
                materials: [],
                attachments: [],
                total_amount: 0,
            },
            costCenterLoading: false,
            costCenters: [],
            inventoriesLoading: false,
            inventories: [],
            clientAccounts: [],
            clientAccountLoading: false,
            cashAccounts: [],
            cashAccountLoading: false,
            disscountAccounts: [],
            disscountAccountLoading: false,
            submitting: false,
            submittingPrint: false,
            submittingConvert: false,
            submittingApprove: false,
            deleteBtnLoading: false,
            deleteAttachmentDialog: false,
            type: null,
        };
    },
    computed: {
        totalQuantities() {
            let val = 0;
            if (this.invoice.materials.length) {
                val = this.invoice.materials.reduce(
                    (prev, curr) => prev + curr.quantity,
                    0
                );
            }
            return val;
        },
        invoiceTotalValue() {
            let val = 0;
            if (this.invoice.materials.length) {
                val = this.invoice.materials.reduce(
                    (prev, curr) =>
                        prev +
                        (curr.allquantity * curr.price - curr.discount_value) +
                        curr.increase_value,
                    0
                );
                if (
                    this.invoice.discount_type === "FIXED" &&
                    this.invoice.discount_value
                ) {
                    val -= this.invoice.discount_value;
                } else if (
                    this.invoice.discount_type === "PERCENTAGE" &&
                    this.invoice.discount_value
                ) {
                    val -= val * (this.invoice.discount_value / 100);
                }
            }
            return val;
        },
    },
    methods: {
        async deleteAttachment(id) {
            try {
                this.deleteBtnLoading = true;
                await axios.delete(`invoice/attachment/${id}`);
                this.deleteAttachmentDialog = false;
                this.$Notifications(
                    this.$t('delete success'),
                    { timeout: 2000, rtl: true },
                    "success"
                );
                this.showAttachment();
            } catch (err) {
            } finally {
                this.deleteBtnLoading = false;
            }
        },
        async showAttachment() {
            const res = await axios.get(`invoice/attachment/${this.$route.params.id}`)
            console.log(res);
            this.invoice.attachments = res.data.attachment;
            this.dialogAttachment = true;
        },
        async getWearHouseTypeMetadata() {
            try {
                this.fetchingMetadata = true;
                const result = await axios.get(
                    `/inventories/delivery-note/meta-data/${this.invoice_type}/${this.invoice.inventory_id.id}`
                );
                this.invoiceMetadata = result.data;
                this.invoice.request_serial = this.invoiceMetadata.next_serial;
            } finally {
                this.fetchingMetadata = false;
            }
        },
        matchMaterialInventoriesHandler() {
            this.invoice.materials.forEach((mat) => {
                mat.inventory_id = this.invoice.inventory_id;
            });

            this.getWearHouseTypeMetadata();

        },
        matchMaterialRelatedAccountHandler() {
            this.invoice.materials.forEach((mat) => {
                mat.account_id = this.invoiceMetadata.invoiceType.related_account;
            });
        },

        async printInvoice() {
            try {

                this.submittingPrint = true;
                const pdf = await axios.get(`inventories/invoices/pdf/${this.$route.params.id}`, {
                    responseType: 'blob',
                });
                saveAs(pdf.data, "invoice.pdf");
            } catch (error) {
                console.error("Errore", error);
            }
            finally {
                this.submittingPrint = false;
            }

        },
        async createNewInvoice() {
            let sisi = '';
            sisi = 'inventories/delivery-note';
            console.log(sisi);
            try {
                this.submitting = true;
                await axios.put(`/inventories/delivery-note/${this.$route.params.id}`, {
                    inventory_id: this.invoice.inventory_id?.id,
                    date: `${this.invoiceDateRequest} ${this.invoiceTimeRequest}`,
                    serial: this.invoice.request_serial,
                    notes: this.invoice.notes,
                    delivery_note_materials: this.invoice.materials.map((mat) => ({
                        invoice_material_template_id: mat.invoice_material_template_id,
                        quantity: mat.quantity,
                        notes: mat.notes,
                    })),
                });
                this.$router.go(-1);
            } finally {
                this.submitting = false;
            }
        },
        async inventoriesAc(value) {
            if (!value) return;
            try {
                this.inventoriesLoading = true;
                const searchResult = await axios.get("/inventories/auto-complete", {
                    params: {
                        query: value,
                    },
                });
                this.inventories = searchResult.data.data;
            } catch (err) {
            } finally {
                this.inventoriesLoading = false;
            }
        },
        async fetchData() {
            let sisi = '/inventories/delivery-note';
            // if(this.){
            //   sisi = '/inventories/delivery-note/order';
            // } else {
            //   sisi = '/inventories/invoice-request';
            // }
            const result = await axios.get(
                `${sisi}/${this.$route.params.id}`
            );
            const dataTemp = result.data.data;
            console.log(dataTemp);
            this.invoiceDateRequest = dataTemp.date;
            //  this.invoiceTimeRequest 
            this.inventories.push(dataTemp.inventory);
            this.invoice.inventory_id = dataTemp.inventory;
            this.invoice.request_serial = dataTemp.serial;
            this.clientAccounts.push(dataTemp.client_account);
            this.invoice.client_account_id = dataTemp.client_account.id;
            this.invoice.materials = dataTemp.delivery_note_materials.map((el) => ({
                ...el,
                allquantity: el.total_quantity,
                deliveried: el.reciverd,
                quantity: el.quantity,
                material_id: el.material,
                notes: el.notes,
                inventory_id: el.inventory,
                account_id: el.account,
                beneficiary_account_id: el.beneficiary_account,
            }));
            this.invoice.notes = dataTemp.notes;
            // this.invoiceTypeFormatter(dataTemp.invoice_type.type) +
            //   " - " +
        }
    },
    async created() {
        this.fetchData();
    },
};
</script>

<style scoped lang="scss">
table {
  border-collapse: collapse;
  table-layout: fixed;
}
.tableId {
 border-radius: 10%;
}
td,
th {
  padding: 0px 10px;
  border-collapse: collapse;
  border-radius: 10%;
}
table,
th,
td {
  border: 1px solid #000;
  border-collapse: collapse;
  border-radius: 10%;
}

#idAutoComp {
  padding-left: 70px;
  padding-right: 70px;
}

#idPrice {
  padding-left: 40px;
  padding-right: 40px;
}

#buffet-table {
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
}

#buffet-table th {
  color: #909399;
  border: 1px solid rgb(216 216 221 / 53%);
  font-size: 1rem;
  text-align: center;
  width: max-content;

}

#buffet-table tr {
  transition: 0.5s;
}
#buffet-table td {
  border: 1px solid rgb(216 216 221 / 53%);
  text-align: center;
  font-size: 1.1rem;
  width: max-content;
}
.add-user__chip1 {
    border: 1px solid #757575;
    border-radius: 10px;
    margin: 5px 0;
    width: 100%;
    /* padding: 5px 0;
    
    height: 70%;*/
    // display: flex; 
    // justify-content: space-around; 
    // align-items: center; 
}

.columnSelector:hover {
    border-right: 2px solid blue;
}

.cardd {
    /* display: flex;
    justify-content: space-between; */
    border: 1px solid #a2a2a2;
    width: 100%;
    border-radius: 5px;
    /* height: 10vw; */
    /* height: 100px; */
    padding: 5px 10px;
    margin: 4px 4px;
    /* background-color: red; */
    /* overflow: hidden; */
    /* cursor: pointer; */
}

.cardd .preview-img {
    width: 100%;
}
</style>
  